
import demo from './demo'

import building from './building'
import household from './household'
import manage from './manage'
import cost from './cost'
import system from './system'


export default [
    ...demo,
    ...building,
    ...household,
    ...manage,
    ...cost,
    ...system
];