export default [
    {
        path: '/system/org',
        name: 'SystemOrg',
        component: () => import(/* webpackChunkName: "system-org" */ '@/views/system/org/Org.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/system/org',
            rbacTag: 'system:org',
        }
    },
    {
        path: '/system/user',
        name: 'SystemUser',
        component: () => import(/* webpackChunkName: "system-user" */ '@/views/system/user/User.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/system/user',
            rbacTag: 'system:user',
        }
    },
    {
        path: '/system/role',
        name: 'SystemRole',
        component: () => import(/* webpackChunkName: "system-role" */ '@/views/system/role/Role.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/system/role',
            rbacTag: 'system:role',
        }
    },
]