import { Loading } from 'element-ui';

export default {
    resetForm() {
        this.$refs.form.resetFields();
    },
    showSuccess(content, onClose) {
        this.$message.closeAll();
        this.$message({ type: 'success', duration: 800, message: content, onClose: onClose });
    },
    showError(content) {
        this.$message.closeAll();
        this.$message({ type: 'error', duration: 1000, message: content });
    },
    showWarn(content) {
        this.$message.closeAll();
        this.$message({ type: 'warning', duration: 1000, message: content });
    },
    confirm(title, done) {
        this.$confirm(title)
            .then(() => {
                done.call(this);
            })
            .catch(() => {});
    },
    arrayGroup(arr, len) {
        let v = [];
        for (let i = 0; i < arr.length/len; i++) {
            let subV = [];
            for (let si = 0; si < len; si++) {
                let k = i*len+si;
                if (k < arr.length) {
                    subV.push(arr[k]);
                } else {
                    break;
                }
            }
            v.push(subV);
        }
        return v;
    },
    fieldTrim(obj, fn) {
        obj[fn] = obj[fn].trim();
    },
    httpThen(req) {
        if (req.data.code == 4) {
            if (this.hasUserInfo()) {
                this.showError(req.data.msg);
            }
            
            this.$router.push({ path: '/login' });
            return true;
        }
    },
    httpCatch(err) {
        console.log(err);
        if (err.response) {
            this.showError('服务异常');
        } else if (err.request) {
            this.showError('响应超时');
        } else {
            this.showError(err.msg);
        }
    },
    load(target = '.el-dialog__body', customClass = 'top-loading') {
        return Loading.service({ target: target, customClass: customClass, background: 'rgba(255, 255, 255, 0.3)' });
    },
    listRemoveItem(list, propName, value) {
        for (let i = 0; i < list.length; i++) {
            let sel = list[i];
            if (sel[propName] == value) {
                list.splice(i, 1);
                i--;
            }
        }
    },
    listHasItem(list, propName, value) {
        for (let i = 0; i < list.length; i++) {
            let sel = list[i];
            if (sel[propName] == value) {
                return true;
            }
        }

        return false;
    },
    getListItem(list, propName, value) {
        for (let i = 0; i < list.length; i++) {
            let sel = list[i];
            if (sel[propName] == value) {
                return sel;
            }
        }
    },
    getListItemIndex(list, propName, value) {
        for (let i = 0; i < list.length; i++) {
            let sel = list[i];
            if (sel[propName] == value) {
                return i;
            }
        }
    },
    findNodes(tree, value, name = 'id', children = 'children') {
        let arr = [];
        this.forEachTree(tree, (node) => {
            if (node[name] == value) {
                arr.push(node);
            }
        }, children);
        return arr;
    },
    forEachTree(tree, cb, children = 'children') {
        if (!tree) {
            return;
        }
        for(let i = 0; i < tree.length; i++) {
            let node = tree[i];
            cb.call(this, node);
            this.forEachTree(node[children], cb);
        }
    },
    hasUserInfo() {
        return this.$store.state.userInfoStatus;
    },
    clearUserAllInfo() {
        this.setUserAllInfo({});
    },
    setUserAllInfo(value) {
        if (!value) {
            return;
        }

        let {
            userName = '' ,
            userType = '',
            deptId = '',
            deptName = '',
            positionId = '',
            positionName = '', 
            orgId = '',
            orgName = '',
            orgType = '',
            rbacList = [],
        } = value;

        this.$store.commit('userInfo', { 
            name: userName, 
            type: userType,
            deptId,
            deptName,
            positionId,
            positionName
        });

        this.$store.commit('org', { id: orgId, name: orgName, type: orgType });
        this.$store.commit('rbacList', rbacList);
        this.$store.commit('userInfoStatus', (userName != ''));
    },
    setUserInfo(value) {
        this.$store.commit('userInfo', value);
    },
    setCurrentOrg(value) {
        this.$store.commit('org', value);
    },
    setRbacList(value) {
        this.$store.commit('rbacList', value);
    },
    getUserInfo() {
        return this.$store.state.userInfo;
    },
    getCurrentOrg() {
        return this.$store.state.org;
    },
    setDefaultUrl(value) {
        this.$store.commit('defaultUrl', value);
    },
    getDefaultUrl() {
        return this.$store.state.defaultUrl;
    },
    isProject() {
        return  this.$store.state.org.type == 2;
    },
    hasPermission(tags, isAll = false) {
        if (this.$store.state.rbacMap) {
            if (this.isArray(tags)) {
                if (isAll) {
                    for (let i = 0; i < tags.length; i++) {
                        if (!this.isString(tags[i]) || (tags[i] !== "*" && !this.$store.state.rbacMap[tags[i]])) {
                            return false;
                        }
                    }
                    return true;
                } else {
                    for (let i = 0; i < tags.length; i++) {
                        if (this.isString(tags[i]) && (tags[i] === '*' || this.$store.state.rbacMap[tags[i]])) {
                            return true;
                        }
                    }
                }
            } else if (this.isString(tags)) {
                if (tags === '*' || this.$store.state.rbacMap[tags]) {
                    return true;
                }
            }
        }
        return false;
    },
    isArray(value) {
        return Object.prototype.toString.call(value) === '[object Array]';
    },
    isString(value) {
        return Object.prototype.toString.call(value) === '[object String]';
    },
    isDigit(value) {
        return /^-?\d+(\.\d*)?$/.test(value);
    },
    formatTime(value, type) {
        if (!value) {
            return '';
        }
        if (type == 1) {
            return value.substring(0, value.length - 3);
        } else if (type == 2) {
            return value.substring(0, value.length - 9);
        } else {
            return value.substring(0, value.length - 3);
        }
    },
    fix(num) {
        if (!this.isDigit(num)) {
            return null;
        }

        if (num < 10) {
            return '0' + num;
        }

        return String(num);
    },
    getLastDate(da) {
        var arr = da.split('-');
        
        if (arr.length < 3) {
            return;
        }

        var d = new Date(arr[0], arr[1], 0);

        return d.getFullYear() + '-' + this.fix(d.getMonth() + 1) + '-' + this.fix(d.getDate());
    },
    getPreMonth(da) {
        var arr = da.split('-');
        
        if (arr.length < 3) {
            return;
        }

        var d = new Date(arr[0], arr[1] - 1, 0);
        if (d.getDate() < arr[2]) {
            arr[2] = d.getDate();
        }

        d = new Date(arr[0], arr[1] - 2, arr[2]);

        return d.getFullYear() + '-' + this.fix(d.getMonth() + 1) + '-' + this.fix(d.getDate());
    },
    toFixed(num, scale) {
        let d = Number(num);
        if (isNaN(d)) {
            return Number(0).toFixed(scale);
        }

        return d.toFixed(scale);
    },
    getMonthText(text) {
        if (!text) {
            return;
        }
        let arr = text.split('-');
        if (arr.length < 2) {
            return '';
        }
        return arr[0] + '年' + arr[1] + '月';
    },
    getDateText(text) {
        if (!text) {
            return;
        }
        let arr = text.split('-');
        if (arr.length < 3) {
            return '';
        }
        return arr[0] + '年' + arr[1] + '月' + arr[2] + '日';
    },
    getLocalDate() {
        let d = new Date();
        return d.getFullYear() + '年' + this.fix(d.getMonth() + 1) + '月' + this.fix(d.getDate())+ '日';
    },
    getLocalTime() {
        let d = new Date();
        return d.getFullYear() + '-' + this.fix(d.getMonth() + 1) + '-' + this.fix(d.getDate())+ ' '
            + this.fix(d.getHours()) + ":" +  this.fix(d.getMinutes()) + ":" + this.fix(d.getSeconds());
    },
    convertCurrency(money) {
        let sign = '';
        if (money.indexOf('-') == 0) {
            sign = '负';
            money = money.substring(1);
        }
        //汉字的数字
        let cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
        //基本单位
        let cnIntRadice = ['', '拾', '佰', '仟'];
        //对应整数部分扩展单位
        let cnIntUnits = ['', '万', '亿', '兆'];
        //对应小数部分单位
        let cnDecUnits = ['角', '分', '毫', '厘'];
        //整数金额时后面跟的字符
        let cnInteger = '整';
        //整型完以后的单位
        let cnIntLast = '元';
        //金额整数部分
        let integerNum;
        //金额小数部分
        let decimalNum;
        //输出的中文金额字符串
        let chineseStr = '';
        //分离金额后用的数组，预定义
        let parts;
        // 传入的参数为空情况 
        if(money == '') {
            return '';
        }
        // 传入的参数为0情况 
        if(money == 0) {
            chineseStr = cnNums[0] + cnIntLast + cnInteger;
            return chineseStr
        }
        // indexOf 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
        if(money.indexOf('.') == -1){
            integerNum = money;
            decimalNum = ''
        }else{
            parts = money.split('.');
            integerNum = parts[0];
            decimalNum = parts[1].substr(0,4);
        }
        //转换整数部分
        if(parseInt(integerNum,10) > 0){
            let zeroCount  = 0;
            let IntLen = integerNum.length
            for(let i = 0; i < IntLen; i++){
                let n = integerNum.substr(i,1);
                let p = IntLen - i - 1;
                let q = p / 4;
                let m = p % 4;
                if( n == '0'){
                    zeroCount ++ ;
                }else{
                    if(zeroCount > 0){
                        chineseStr += cnNums[0]
                    }
                    zeroCount = 0;
                    chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                }
                if(m == 0 && zeroCount < 4){
                    chineseStr += cnIntUnits[q]; 
                }
            }
            // 最后+ 元
            chineseStr += cnIntLast;
        }
        // 转换小数部分
        if(decimalNum != ''){
            let decLen = decimalNum.length;
            for(let i = 0; i <decLen; i++){
                let n = decimalNum.substr(i,1);
                if(n != '0'){
                    chineseStr += cnNums[Number(n)] + cnDecUnits[i]
                }
            }
        }
        if(chineseStr == ''){
            chineseStr += cnNums[0] + cnIntLast + cnInteger;
        }else if(decimalNum == ''){
            chineseStr += cnInteger;
        }
    
        return sign+chineseStr
    }    
};