export default [
    {
        path: '/household/person',
        name: 'HouseholdPerson',
        component: () => import(/* webpackChunkName: "household-person" */ '@/views/household/person/Person.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/household/person',
            rbacTag: 'household:person',
        }
    },
    {
        path: '/household/subcontractor',
        name: 'HouseholdSubcontractor',
        component: () => import(/* webpackChunkName: "household-subcontractor" */ '@/views/household/subcontractor/Subcontractor.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/household/subcontractor',
            rbacTag: 'household:subcontractor',
        }
    },
];