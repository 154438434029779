export default [
    {
        id: 2,
        icon: 'el-icon-house',
        title: '住宿管理',
        tag: 'stay',
        children: [
            {
                id: 200,
                icon: 'el-icon-position',
                title: '分包单位管理',
                href: '/household/subcontractor',
                tag: 'household:subcontractor',
            },
            {
                id: 201,
                icon: 'el-icon-tickets',
                title: '缴费管理',
                href: '/cost/charge',
                tag: 'cost:charge',
            },
            // {
            //     id: 202,
            //     icon: 'el-icon-money',
            //     title: '房间入住',
            //     href: '/manage/checkin',
            //     tag: 'manage:checkin',
            // },
            {
                id: 202,
                icon: 'el-icon-money',
                title: '房间入住',
                href: '/manage',
                tag: 'manage',
            },
            {
                id: 203,
                icon: 'el-icon-takeaway-box',
                title: '罚款登记',
                href: '/fine/record',
                tag: 'fine:record',
            },
            // {
            //     id: 204,
            //     icon: 'el-icon-copy-document',
            //     title: '房间退宿',
            //     href: '/manage/checkout',
            //     tag: 'manage:checkout',
            // },
            {
                id: 204,
                icon: 'el-icon-copy-document',
                title: '房间退宿',
                href: '/manage/checkout',
                tag: 'manage:checkout',
            },
            {
                id: 610,
                icon: 'el-icon-copy-document',
                title: '资金划拨',
                href: '/cost/convert',
                tag: 'cost:convert',
            },
            {
                id: 301,
                icon: 'el-icon-edit-outline',
                title: '月度账单',
                href: '/cost/bill',
                tag: 'cost:bill',
            },
            {
                id: 205,
                icon: 'el-icon-connection',
                title: '退费管理',
                href: '/cost/refund',
                tag: 'cost:refund',
            },
            {
                id: 206,
                icon: 'el-icon-film',
                title: '住宿记录',
                href: '/manage/checklog',
                tag: 'manage:checklog',
            },
        ]
    },
    {
        id: 3,
        icon: 'el-icon-c-scale-to-original',
        title: '报表管理',
        tag: 'cost',
        children: [
            {
                id: 302,
                icon: 'el-icon-files',
                title: '资金台账',
                href: '/cost/rent',
                tag: 'cost:rent',
            },
            {
                id: 304,
                icon: 'el-icon-warning-outline',
                title: '费用预警',
                href: '/cost/earlywarn',
                tag: 'cost:early-warn',
            },
            {
                id: 303,
                icon: 'el-icon-notebook-1',
                title: '费用报表',
                href: '/cost/report',
                tag: 'cost:report',
            }
        ]
    },
    {
        id: 4,
        icon: 'el-icon-edit-outline',
        title: '劳务人员管理',
        href: '/household/person',
        tag: 'household:person',
    },
    {
        id: 5,
        icon: 'el-icon-collection-tag',
        title: '基础信息设置',
        tag: 'base',
        children: [
            {
                id: 501,
                icon: 'el-icon-tickets',
                title: '房间基础设置',
                href: '/building/room',
                tag: 'building:room',
            },
            {
                id: 502,
                icon: 'el-icon-files',
                title: '费用类型配置',
                href: '/cost/rule',
                tag: 'cost:rule',
            }
        ]
    },
    // {
    //     // id: 2,
    //     // icon: 'el-icon-document-copy',
    //     // title: '宿舍管理',
    //     // tag: 'building',

    //     id: 2,
    //     icon: 'el-icon-document-copy',
    //     title: '房间管理',
    //     tag: 'building',
    //     children: [
    //         {
    //             id: 202,
    //             icon: 'el-icon-tickets',
    //             title: '房间基础配置',
    //             href: '/building/room',
    //             tag: 'building:room',
    //         },
    //         {
    //             id: 4,
    //             icon: 'el-icon-money',
    //             title: '房务管理',
    //             href: '/manage',
    //             tag: 'manage',
    //         },
    //         // {
    //         //     id: 22,
    //         //     icon: 'el-icon-timer',
    //         //     title: '房间管理',
    //         //     href: '/building/room',
    //         //     tag: 'building:room',
    //         // }
    //     ]
    // },
    // {
    //     id: 3,
    //     icon: 'el-icon-shopping-cart-1',
    //     title: '住户管理',
    //     tag: 'household',
    //     children: [
    //         {
    //             id: 31,
    //             icon: 'el-icon-edit-outline',
    //             title: '住户实名制登记',
    //             href: '/household/person',
    //             tag: 'household:person',
    //         },
    //         {
    //             id: 32,
    //             icon: 'el-icon-position',
    //             title: '分包单位登记',
    //             href: '/household/subcontractor',
    //             tag: 'household:subcontractor',
    //         },
    //     ]
    // },
    // {
    //     id: 5,
    //     icon: 'el-icon-coin',
    //     title: '费用管理',
    //     tag: 'cost',
    //     children: [
    //         {
    //             id: 41,
    //             icon: 'el-icon-tickets',
    //             title: '费用管理',
    //             href: '/cost/charge',
    //             tag: 'cost:charge',
    //         },
    //         {
    //             id: 42,
    //             icon: 'el-icon-edit-outline',
    //             title: '月度账单',
    //             href: '/cost/bill',
    //             tag: 'cost:bill',
    //         },
    //         {
    //             id: 43,
    //             icon: 'el-icon-notebook-1',
    //             title: '押金管理',
    //             href: '/cost/deposit',
    //             tag: 'cost:deposit',
    //         },
    //         {
    //             id: 44,
    //             icon: 'el-icon-notebook-2',
    //             title: '租金管理',
    //             href: '/cost/rent',
    //             tag: 'cost:rent',
    //         },
    //         {
    //             id: 45,
    //             icon: 'el-icon-files',
    //             title: '费用规则配置',
    //             href: '/cost/rule',
    //             tag: 'cost:rule',
    //         },
    //         {
    //             id: 46,
    //             icon: 'el-icon-notebook-1',
    //             title: '费用报表',
    //             href: '/cost/report',
    //             tag: 'cost:report',
    //         },
    //     ]
    // },
    {
        id: 6,
        icon: 'el-icon-setting',
        title: '系统管理',
        tag: 'system',
        children: [
            {
                id: 61,
                icon: 'el-icon-office-building',
                title: '组织机构管理',
                href: '/system/org',
                tag: 'system:org',
            },
            {
                id: 62,
                icon: 'el-icon-user',
                title: '人员管理',
                href: '/system/user',
                tag: 'system:user',
            },
            {
                id: 63,
                icon: 'el-icon-postcard',
                title: '角色权限管理',
                href: '/system/role',
                tag: 'system:role',
            },
        ]
    },
];