export default [
    {
        path: '/demo/base',
        name: 'DemoBase',
        component: () => import(/* webpackChunkName: "demo-base" */ '@/views/demo/base/Base.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/demo/base',
            rbacTag: 'demo:base',
        }
    },
    {
        path: '/demo/leftTree',
        name: 'DemoLeftTree',
        component: () => import(/* webpackChunkName: "demo-left-tree" */ '@/views/demo/leftTree/LeftTree.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/demo/leftTree',
            rbacTag: 'demo:tree',
        }
    }
];