export default [
    {
        path: '/manage',
        name: 'Manage',
        component: () => import(/* webpackChunkName: "manage" */ '@/views/manage/Manage.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/manage',
            rbacTag: 'manage',
        }
    },
    {
        path: '/manage/checkin',
        name: 'ManageCheckin',
        component: () => import(/* webpackChunkName: "manage-checkin" */ '@/views/manage/checkin/Checkin.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/manage/checkin',
            // rbacTag: 'manage:checkin',
            rbacTag: 'manage',
        }
    },
    {
        path: '/manage/checkout',
        name: 'ManageCheckout',
        component: () => import(/* webpackChunkName: "manage-checkout-list" */ '@/views/manage/checkout/CheckoutList.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/manage/checkout',
            // rbacTag: 'manage:checkout',
            rbacTag: 'manage',
        }
    },
    {
        path: '/manage/checklog',
        name: 'ManageChecklog',
        component: () => import(/* webpackChunkName: "manage-checklog" */ '@/views/manage/checklog/CheckLog.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/manage/checklog',
            // rbacTag: 'manage:checkout',
            rbacTag: 'manage:checklog',
        }
    },
]