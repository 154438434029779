<template>
    <div class="frame-wrap" v-bind:class="{ 'menu-fold': isFold, 'menu-unfold': !isFold }">
        <Head 
            :title="title" 
            :org-tree="orgTree"
            :org="org"
            :user-info="userInfo"
            @org-change="handleOrgChange"
            @logout="handleLogout"
            @switchMenuFold="switchMenuFold">
        </Head>
        <template v-if="$store.state.userInfoStatus">
            <Menu :isCollapse="isFold" :menuList="menuList" @select="handleMenuSelect"></Menu>
            <el-breadcrumb separator-class="el-icon-arrow-right" class="crumb">
                <template v-for="(item,index) in crumbList">
                    <el-breadcrumb-item v-if="index == 0" :key="index"><span class="icon" :class="item.icon"></span>{{item.title}}</el-breadcrumb-item>
                    <el-breadcrumb-item v-else :key="'el_'+index">{{item.title}}</el-breadcrumb-item>
                </template>
            </el-breadcrumb>
            <router-view v-if="showView && hasPermission($route.meta.rbacTag)"></router-view>
        </template>
        <div v-else class="loading-full"></div>
    </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Menu from '@/components/Menu.vue'
import http from '@/utils/http'
import common from '@/utils/common'
import { Loading } from 'element-ui'
import menuAllList from '@/data/menu'

export default {
    name: 'Frame',
    components: {
        Head,
        Menu
    },
    data(){
        return {
            isFold: false,
            title: '工友社区宿舍管理系统',
            orgTree: [],
            org: {},
            userInfo: {},
            allMenuList: menuAllList,
            menuList: [],
            crumbList: [
                {
                    icon: 'icon el-icon-house',
                    title: '系统首页',
                }
            ],
            loading: null,

            showView: false,
        }
    },
    created() {
        if (!this.hasUserInfo()) {
            http.getUserInfo().then(req => {
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    this.closeLoading();
                    this.setUserAllInfo(req.data.data);
                    this.org = this.getCurrentOrg();
                    this.userInfo = this.getUserInfo();
                    this.loadUserOrgTree();
                } else {
                    this.closeLoading();
                    this.showError(req.data.msg);
                }
            });
        } else {
            this.org = this.getCurrentOrg();
            this.userInfo = this.getUserInfo();
            this.loadUserOrgTree();
        }
    },
    mounted() {
        if (!this.hasUserInfo()) {
            this.loading = Loading.service({ target: '.loading-full', background: '#f9f9f9' });
        }
    },
    watch: {
        ['$route.meta.rbacTag'] () { 
            this.checkPermission(); 
        },
        ['$store.state.rbacList']: {
            immediate: true,
            handler() { 
                this.menuFilter();
                this.checkPermission(); 
            },
        }, 
    },
    methods: {
        ...common,
        loadUserOrgTree() {
            http.get('v1/user/org/tree').then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.orgTree = req.data.data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        menuFilter() {
            let defaultUrl = '';
            this.forEachTree(this.allMenuList, (node) => {
                if (this.hasPermission(node.tag)) {
                    node.isAuth = true;
                    if (defaultUrl === '' && node.href) {
                        defaultUrl = node.href;
                    }
                } else {
                    node.isAuth = false;
                }
            });
            this.menuList = this.copyFilter(this.allMenuList);
            // console.log(this.allMenuList);
            // console.log(this.menuList);
        },
        copyFilter(list) {
            let arr = [];
            for (let i = 0; i < list.length; i++) {
                if (list[i].isAuth) {
                    let item = {...list[i]};
                    arr.push(item);
                    if (item.children) {
                        item.children = this.copyFilter(list[i].children);
                    }
                }
            }
            return arr;
        },
        checkPermission() {
            if (this.$route.meta.isAuth && this.hasUserInfo() && !this.hasPermission(this.$route.meta.rbacTag)) {
                this.$alert('没有访问权限，如有疑问请联系系统管理员。', '提示');
            }
        },
        closeLoading() {
            if (this.loading != null) {
                this.loading.close();
                this.loading = null;
            }
        },
        switchMenuFold(value) {
            this.isFold = value;
        },
        handleMenuSelect(indexPath) {
            this.crumbList = indexPath;
            this.updateFrame();
        },
        updateFrame() {
            this.showView = false;
            this.$nextTick(() => {
                this.showView = true;
            });
        },
        handleLogout() {
            http.get('v1/logout').then(req => {
                if (req.data.code == http.SUCCESS) {
                    localStorage.removeItem('token');
                    this.$router.push({path: '/login'});
                    this.clearUserAllInfo();
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleOrgChange(org) {
            let currentOrg = this.getCurrentOrg();
            if (currentOrg.id != org.id) {
                let data = {};
                data.beforeOrgId = currentOrg.id;
                data.afterOrgId = org.id;
                let loading = this.load();
                http.post('v1/org/changeOrg', data).then(req => {
                    loading.close();
                    if (req.data.code == http.SUCCESS) {
                        this.setUserAllInfo(req.data.data);
                        //this.orgList = this.getOrgList();
                        this.org = this.getCurrentOrg();
                        this.userInfo = this.getUserInfo();
                        this.updateFrame();
                    } else {
                        this.showError(req.data.msg);
                    }
                }).catch(err => {
                    loading.close();
                    this.httpCatch(err);
                });
            }
        }
    }
};
</script>

<style scoped>
.crumb {
    height: 32px;
    border-bottom: 1px solid #e3ebed;
    line-height: 32px;
    padding-left: 15px;
    position: fixed;
    top: 64px;
    right: 0;
    left: 221px;
    background-color: #f9f9f9;
    z-index: 10;
}
.icon {
    margin-right: 4px;
    font-size: 16px;
    position: relative;
    top: 1px;
}
.loading-full {
    position: absolute;
    top: 63px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1022;
}
</style>
