import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfoStatus: false,
        // 用户信息
        userInfo: {
            name: '', 
            type: '',
            deptId: '',
            deptName: '',
            positionId: '',
            positionName: '',

            orgId: '',
            orgName: '',
            orgType: '',
        },
        // 当前组织
        org: {
            id: '',
            name: '',
            type: '',
        },
        // 权限列表
        rbacList: [],
        // 权限map
        rbacMap: {},
        // 默认页面 第一个对应页面的菜单
        defaultUrl: '',
    },
    mutations: {
        userInfoStatus(state, value) {
            state.userInfoStatus = value;
        },
        userInfo(state, value) {
            state.userInfo = value;
        },
        org(state, value) {
            state.org = value;
        },
        rbacList(state, value) {
            if (!value) {
                state.rbacList = [];
                state.rbacMap = {};
                return;
            }

            state.rbacList = value;

            let maps = {};
            for (let i = 0; i < value.length; i++) {
                let item = value[i];
                maps[item.tag] = item;
            }
            state.rbacMap = maps;
        },
        defaultUrl(state, value) {
            state.defaultUrl = value;
        },
    },
    actions: {
        
    },
    modules: {
        
    }
})
