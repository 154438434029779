import axios from 'axios';

let baseURL = 'http://localhost:8080/';
// let baseURL = 'http://192.168.1.102:8080/wozhou/';
// let baseURL = 'http://43.143.210.228:8080/';
if (process.env.NODE_ENV === 'production') {
    //baseURL = 'http://123.57.217.73:8080/wozhou/';
    //baseURL = 'http://api.wozhoushiye.com/';
    baseURL = 'http://123.57.132.2:8080/';
    // baseURL = 'http://47.115.201.205:8080/';
}

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    config => {
        if (localStorage.getItem("token") != null) {
            config.headers["token"] = localStorage.getItem("token");
        }
        return config;
    },
    err => Promise.reject(err)
);

export default {
    SUCCESS: 0,
    BASE_URL: baseURL,
    MOCK_BASE_API_URL: process.env.VUE_APP_BASE_URL + '/',
    wait(arr) {
        return axios.all(arr);
    },
    get(url, params) {
        return axios.get(url, params);
    },
    post(url, data) {
        return axios.post(url, data);
    },
    put(url, data) {
        return axios.put(url, data);
    },
    download(url,data){
        axios({
            method: 'get',
            url: baseURL+url,
            // 二进制流文件，一定要设置成blob，默认是json
            responseType: 'blob'
        }).then(res => {
            const blob = new Blob([res.data], {type: data.type});
            let dom = document.createElement('a');
            let url = window.URL.createObjectURL(blob);
            dom.href = url;
            dom.download = decodeURI(data.filename);
            dom.style.display = 'none';
            document.body.appendChild(dom);
            dom.click();
            dom.parentNode.removeChild(dom);
            window.URL.revokeObjectURL(url);
        })
    },
    delete(url, data) {
        return axios({
            method: 'delete',
            headers: {'Content-Type': 'application/json; charset=utf-8'},
            url: url,
            ...data
        });
    },
    getUserInfo() {
        return this.get('v1/user/userSessionInfo');
    },
    getOrderNo() {
        return this.get('v1/goodEntryForm/generateOrderNo');
    },
}
