import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/public.css'
import './assets/iconfont/css/font-awesome.css'

import echarts from 'echarts/lib/echarts'
Vue.prototype.$echarts = echarts

import _ from 'lodash'
Vue.prototype._ = _;

// 影响 blob 下载，获取 download数据内容 的格式
// import './api/mock'

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
