export default [
    // {
    //     path: '/building/building',
    //     name: 'BuildingBuilding',
    //     component: () => import(/* webpackChunkName: "building-building" */ '@/views/building/building/Building.vue'),
    //     meta: {
    //         isAuth: true,
    //         isFrame: true,
    //         menuActiveTag: '/building/building',
    //         rbacTag: 'building:building',
    //     }
    // },
    {
        path: '/building/room',
        name: 'BuildingRoom',
        component: () => import(/* webpackChunkName: "building-room" */ '@/views/building/room/Room.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/building/room',
            rbacTag: 'building:room',
        }
    },
];