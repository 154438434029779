export default [
    {
        path: '/cost/charge',
        name: 'CostCharge',
        component: () => import(/* webpackChunkName: "cost-charge" */ '@/views/cost/charge/Charge.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/cost/charge',
            rbacTag: 'cost:charge',
        }
    },
    {
        path: '/cost/bill',
        name: 'CostBill',
        component: () => import(/* webpackChunkName: "cost-bill" */ '@/views/cost/bill/Bill.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/cost/bill',
            rbacTag: 'cost:bill',
        }
    },
    {
        path: '/cost/deposit',
        name: 'CostDeposit',
        component: () => import(/* webpackChunkName: "cost-deposit" */ '@/views/cost/deposit/Deposit.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/cost/deposit',
            rbacTag: 'cost:deposit',
        }
    },
    {
        path: '/cost/rent',
        name: 'CostRent',
        component: () => import(/* webpackChunkName: "cost-rent" */ '@/views/cost/rent/Rent.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/cost/rent',
            rbacTag: 'cost:rent',
        }
    },
    {
        path: '/cost/rule',
        name: 'ContRule',
        component: () => import(/* webpackChunkName: "cost-rule" */ '@/views/cost/rule/Rule.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/cost/rule',
            rbacTag: 'cost:rule',
        }
    },
    {
        path: '/cost/report',
        name: 'CostReport',
        component: () => import(/* webpackChunkName: "cost-report" */ '@/views/cost/report/Report.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/cost/report',
            rbacTag: 'cost:report',
        }
    },
    {
        path: '/fine/record',
        name: 'FineRecord',
        component: () => import(/* webpackChunkName: "fine-record" */ '@/views/fine/record/FineRecord.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/fine/record',
            rbacTag: 'fine:record',
        }
    },
    {
        path: '/cost/refund',
        name: 'CostRefund',
        component: () => import(/* webpackChunkName: "cost-charge" */ '@/views/cost/refund/Refund.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/cost/refund',
            rbacTag: 'cost:refund',
        }
    },
    {
        path: '/cost/convert',
        name: 'CostConvert',
        component: () => import(/* webpackChunkName: "cost-convert" */ '@/views/cost/convert/Convert.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/cost/convert',
            rbacTag: 'cost:convert',
        }
    },
    {
        path: '/cost/earlywarn',
        name: 'CostEarlywarn',
        component: () => import(/* webpackChunkName: "cost-early-warn" */ '@/views/cost/earlywarn/Earlywarn.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            menuActiveTag: '/cost/earlywarn',
            rbacTag: 'cost:early-warn',
        }
    },
];