import Vue from 'vue'
import VueRouter from 'vue-router'
import module from './module/index'

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/',
    //     name: 'Index',
    //     component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue'),
    //     meta: {
    //         isAuth: false,
    //         isFrame: true,
    //         menuActiveTag: '/',
    //         rbacTag: 'index',
    //     }
    // },
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            isAuth: false,
            isFrame: false
        },
    },
    {
        path: '/alter-password',
        name: 'AlterPassword',
        component: () => import(/* webpackChunkName: "alter-password" */ '../views/AlterPassword.vue'),
        meta: {
            isAuth: true,
            isFrame: true,
            rbacTag: '*',
        }
    },
    {
        path: '/build/room/info',
        name: 'RoomInfoQR',
        component: () => import(/* webpackChunkName: "room-info-qr" */ '../views/building/room/RoomInfoQR.vue'),
        meta: {
            isAuth: false,
            isFrame: false,
        }
    },
    {
        path: '/404',
        name: 'Error404',
        component: () => import(/* webpackChunkName: "error-404" */ '../views/404.vue'),
        meta: {
            isAuth: false,
            isFrame: false
        }
    },
    {
        path: "*",
        redirect: "/404"
    },
    ...module
]

// 重定向回原路径报错问题处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject);
	}

    return originalPush.call(this, location).catch(err => err);
}

const router = new VueRouter({
    mode: 'history',
    //mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

// 判断用户是否登陆
router.beforeEach((to, from, next) => {
    let token = localStorage.getItem('token');
    if (to.meta.isAuth) {
        if (token === null || token === '') {
            next('/login');
        } else {
            next();
        }
    } else {
        // if (token != null && token != '') {
        //     next('/');
        // } else {
            next();
        //}
    }
})

export default router
