<template>
    <el-dropdown
        trigger="click">
        <span class="el-dropdown-link" ref="text">
            {{ text }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
            <div class="search-box"><el-input type="text" v-model="filterTreeText" placeholder="搜索" size="small"></el-input></div>
            <div class="tree-box">
                <Scrollbar>
                    <el-tree 
                        ref="tree"
                        :node-key="nodeKey"
                        :default-expand-all="defaultExpandAll"
                        :expand-on-click-node="false"
                        :data="data" 
                        :show-checkbox="false"
                        :check-strictly="false"
                        :props="treeProps" 
                        :check-on-click-node="true"
                        :filter-node-method="localFilterNodeMethod"
                        @node-click="handleNodeClick"></el-tree>
                </Scrollbar>
            </div>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import Scrollbar from '@/components/Scrollbar'

export default {
    name: 'SelectOrg',
    components: {
        Scrollbar
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        placeholder: {
            type: String,
            default() {
                return '';
            },
        },
        value: {
            type: [String, Number, Array],
            default() {
                return '';
            },
        },
        data: {
            type: Array,
            default() {
                return [];
            },
        },
        defaultExpandAll: {
            type: Boolean,
            default() {
                return false;
            },
        },
        filterable: {
            type: Boolean,
            default() {
                return true;
            },
        },
        nodeKey: {
            type: String,
            default() {
                return 'id';
            },
        },
        treeProps: {
            type: Object,
            default() {
                return { 
                    children: 'children', 
                    label: 'label' 
                };
            },
        },
        filterNodeMethod: {
            type: Function,
            default: null,
        }
    },
    data(){
        return {
            filterTreeText: '',
            text: this.emptyValue(),
            selectedData: [],
            scrollTop: 0,
        }
    },
    created() {
        this.$nextTick(() => {
            this.selectData(this.value);
        });
        if (this.filterNodeMethod != null) {
            this.localFilterNodeMethod = this.filterNodeMethod;
        }
    },
    watch: {
        data () {
            this.$nextTick(() => {
                this.selectData(this.value);
            });
        },
        value (value) {
            if (value === this.text) {
                return;
            }
            this.$nextTick(() => {
                this.selectData(value);
            });
        },
        filterTreeText(val) {
            this.$refs.tree.filter(val);
        },
    },
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        emptyValue() {
            let text;
            text = '';
            return text;
        },
        selectData(value) {            
            this.$refs.tree.setCurrentKey(value);
            let node = this.$refs.tree.getNode(value);

            if (node) {
                this.text = this.getText(node);
                this.selectedData = [ node.data ];
            }
        },
        getText(node){
            return node.data[this.treeProps.label];
        },
        filterMethod(value) {
            this.$refs.tree.filter(value);
        },
        localFilterNodeMethod(value, data) {
            if (!value) return true;
            return data[this.treeProps.label].indexOf(value) !== -1;
        },
        handleNodeClick(data) {
            if (this.multiple) {
                return;
            }

            let node = this.$refs.tree.getNode(data);
            if (node.level <= this.selectMinLevel) {
                node.expanded = !node.expanded;
                let key = null;
                if (this.selectedData.length > 0) {
                    key = this.selectedData[0];
                }
                this.$refs.tree.setCurrentKey(key);
                return;
            }

            this.text = this.getText(node);
            this.$refs.text.click();
            this.selectedData = [ data ];
            console.log(this.selectedData)
            this.$emit('change', data[this.nodeKey], data);
        },
        handleVisibleChange(visible) {
            if (visible) {
                if (this.filterable) {
                    this.filterMethod('');
                }

                let scrollbarWrap = this.findScrollbarWrap();

                if (scrollbarWrap) {
                    setTimeout(() => {
                        scrollbarWrap.scrollTop = this.scrollTop;
                    }, 50);
                }
            } else {
                let scrollbarWrap = this.findScrollbarWrap();

                if (scrollbarWrap) {
                    this.scrollTop = scrollbarWrap.scrollTop;
                }
            }
        },
        findScrollbarWrap() {
            return this.findParents(this.$refs.tree.$el, function (node) {
                if (node.className.indexOf('el-scrollbar__wrap') != -1) {
                    return true;
                }
            });
        },
        findParents(el, callback) {
            if (callback(el)) {
                return el;
            }

            if (el.parentNode) {
                return this.findParents(el.parentNode, callback);
            }
        },
    }
};
</script>

<style scoped>
.search-box {
    padding: 0 10px 10px;
    border-bottom: 1px solid #eee;
}
.tree-box {
    padding: 10px 8px 0;
    width: 340px;
}
.tree-box /deep/ .el-scrollbar__wrap {
    overflow-x: auto;
    max-height: 80vh;
}
.tree-box /deep/ .el-tree-node__content {
    display: table;
    align-items: normal;
    width: 100%;

}
.tree-box /deep/ .el-tree-node.is-expanded>.el-tree-node__children {
    display: table;
    overflow: visible;
}
.el-tree {
    padding: 0 10px 17px 0;
}
</style>