<template>
    <div id="app">
        <Frame v-if="$route.meta.isFrame"></Frame>
        <router-view v-else></router-view>
    </div>
</template>

<script>
import Frame from '@/views/Frame.vue'
// import im from '@/utils/im'

export default {
    name: 'App',
    components: {
        Frame
    },
    data(){
        return {}
    },
    created() {
        
    },
    watch: {
        // ['$store.state.userInfoStatus']: {
        //     immediate: true,
        //     handler (value) {
        //         if (value) {
        //             im.init((data) => {
        //                 if (data.code == 0) {
        //                     this.$notify({
        //                         title: '消息提醒',
        //                         message: data.data,
        //                         position: 'bottom-right',
        //                         duration: 5000
        //                     });
        //                 }
        //             });
        //         } else {
        //             im.destroy();
        //         }
        //     }
        // }
    },
    methods: {
        
	}
};
</script>

<style lang="scss">
#app {
    font: 14px/24px "PingFang SC", "Lantinghei SC", "Microsoft YaHei", arial;
    color: #333;
}
</style>
