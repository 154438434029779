<template>
    <el-scrollbar ref="leftScrollbar" :style="wrapStyle">
        <slot></slot>
    </el-scrollbar>
</template>

<script>
export default {
    name: 'Scrollbar',
    props: {
        resize: {
            type: Function
        }
    },
    data(){
        return {
            wrapStyle: {},
        };
    },
    mounted () {
        window.addEventListener('resize', this.update, false);
        this.update();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.update, false);
    },
    methods: {
        update() {
            if (this.resize) {
                this.$refs.leftScrollbar.$el.style.height = this.resize();
            }
            
            this.$refs.leftScrollbar.update();
        }
    }
};
</script>

<style scoped>
</style>