<template>
    <div class="head">
        <div class="head-bg">
        <div class="logo">{{title}}</div>
        <div class="menu-switch">
            <ul>
                <li
                    @click="handleLogout"
                    class="logout">
                    <i class="icon icon-share-alt"></i>退出
                </li>
            </ul>
        </div>
        <div class="menu-bar">
            <ul>
                <li v-if="orgTree && orgTree.length > 0" class="project-list">
                    <SelectOrg
                        ref="selectTree"
                        placeholder="请选择"
                        v-model="orgId"
                        :data="orgTree"
                        :treeProps="{ children: 'children', label: 'name' }"
                        default-expand-all
                        filterable
                        clearable
                        style="width:100%;"
                        size="small"
                        @change="handleSelectTreeChange"
                    ></SelectOrg>
                </li>
                <li v-if="userInfo && userInfo.name != ''" class="user-info">
                    <span v-if="userInfo.type != 2"><template v-if="userInfo.deptName || userInfo.positionName"><i>{{userInfo.deptName}}{{userInfo.positionName}}</i>：</template><i>{{userInfo.name}}</i></span>
                    <span v-if="userInfo.type == 2"><i>供应商</i>：<i>{{userInfo.name}}</i></span>
                </li>
            </ul>
        </div>
        </div>
    </div>
</template>

<script>
import SelectOrg from "@/components/SelectOrg"

export default {
    name: 'Head',
    components: {
        SelectOrg
    },
    props: {
        title: {
            type: String,
            default() {
                return ""
            }
        },
        userInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        orgTree: {
            type: Array,
            default() {
                return []
            }
        },
        org: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        ['org.id']: {
            handler(value) {
                this.orgId = value;
            },
            immediate: true,
        }
    },
    data(){
        return {
            orgId: '',
            isFold: false,
            restPassword: "restPassword",
            logout: "logout",
            postName: '',
        }
    },
    methods: {
        menuSwitch() {
            this.isFold = !this.isFold;
            this.$emit('switchMenuFold', this.isFold);
        }, 
        handleLogout() {
            this.$emit('logout', this.isFold);
        },
        handleSelectTreeChange(orgId, node) {
            this.$emit('org-change', node);
        },
    }
};
</script>

<style scoped>
.head {
    width: 100%;
    height: 64px;
    background: url(../assets/images/layout/header-bg.jpg) repeat-x top #e3ebed;
    background-size: 1px 63px;
    position: fixed;
    z-index: 1021;
}
.head-bg {
    height: 100%;
    background: url(../assets/images/layout/top-bg.png) no-repeat left top;
    /* background-size: 750px 63px; */
    background-size: 100% 63px;
}
.head .logo {
    float: left;
    
    padding: 25px 0 0 0;
    width: 220px;
    line-height: 20px;

    text-align: center;

    font-size: 20px;
    color: #fff;

    font-weight: normal;
}
.menu-switch { 
    float: left;
    padding: 16px 0 0 0;
    line-height: 20px;
}
.menu-switch .fold,
.menu-switch .unfold {
    width: 60px;
    height: 50px;
    cursor: pointer;
}
.menu-switch .fold {
    background: url(../assets/images/layout/fold.png) no-repeat center;
    background-size: 26px;
}
.menu-switch .unfold {
    background: url(../assets/images/layout/unfold.png) no-repeat center;
    background-size: 26px;
}
.menu-switch .fold:hover,
.menu-switch .unfold:hover {
    background-color: #416898;
}
.menu-switch .logout {
    color: #fff;
    padding: 9px 10px;
    display: block;
    font-size: 16px;
    cursor: pointer;
}
.menu-switch .logout .icon {
    opacity: 0.5;
    margin-right: 3px;
}
.menu-switch .logout:hover {
    background: #1E9FFF;
}
.menu-switch .logout:hover .icon {
    opacity: 1;
}
.menu-bar {
    float: right;
    padding: 26px 20px 0 0;
}
.menu-bar .user {
    height: 45px;
    padding: 6px 10px 0 10px;
    margin-right: 20px;
    color: #fff;
    float: left;
    cursor: pointer;
}
.menu-bar .user:hover {
    background-color: #416898;
}
.menu-bar .user .el-dropdown {
    color: #fff;
}
.menu-bar .user .icon { 
    float: left; 
    width: 38px;
    height: 38px;
    line-height: 38px;
    font-size: 26px;
    background-color: #333;
    border-radius: 50%;
    text-align: center;
}
.menu-bar .user .name { 
    float: left;
    padding: 8px 0 0 8px;
    font-size: 16px;
}

.menu-bar /deep/ .project-list {
    float: left;
    line-height: 20px;
    cursor: pointer;
    margin-right: 15px;
}

.menu-bar /deep/ .project-list .el-dropdown-link {
    font-size: 16px;
    color: #fff;
}

.menu-bar .user-info {
    float: left;
    line-height: 20px;
    color: #fff;
    font-size: 14px;
}
.menu-bar .user-info i {
    font-style: normal;
}
</style>