<template>
    <div class="side-menu">
        <div class="side-menu-inner">
            <el-menu
                class="side-menu-vertical"
                @select="handleSelect"
                @open="handleOpen"
                @close="handleClose"
                text-color="#333"
                active-text-color="#fff"
                :default-active="$route.meta.menuActiveTag"
                :unique-opened="false"
                :router="true"
                :collapse-transition="false"
                :collapse="isCollapse"
                ref="sideMenu">
                <template v-for="item in menuList">
                    <el-menu-item v-if="!item.children" :index="item.href" :route="item.href" :key="item.id">
                        <i :class="item.icon ? item.icon : ''"></i>
                        <span slot="title">{{item.title}}</span>
                    </el-menu-item>
                    <el-submenu v-else :index="String(item.id)" :key="item.id">
                        <template slot="title">
                            <i :class="item.icon ? item.icon : ''"></i>
                            <span>{{item.title}}</span>
                        </template>
                        <template v-for="item1 in item.children">
                            <el-menu-item v-if="!item1.children" :index="item1.href" :route="item1.href" :key="item1.id">
                                <i :class="item1.icon ? item1.icon : ''"></i>
                                <span slot="title">{{item1.title}}</span>
                            </el-menu-item>
                            <el-submenu v-else :index="String(item1.id)" :key="item1.id">
                                <template slot="title">
                                    <i :class="item1.icon ? item1.icon : ''"></i>
                                    <span>{{item1.title}}</span>
                                </template>
                                <template v-for="item2 in item1.children">
                                    <el-menu-item v-if="!item2.children" :index="item2.href" :route="item2.href" :key="item2.id">
                                        <i :class="item2.icon ? item2.icon : ''"></i>
                                        <span slot="title">{{item2.title}}</span>
                                    </el-menu-item>
                                </template>
                            </el-submenu>
                        </template>
                    </el-submenu>
                </template>
            </el-menu>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Menu',
    props: {
        isCollapse: {
            type: Boolean,
            default() {
                return false
            }
        },
        menuList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data(){
        return {
            menuMap: {}
        }
    },
    created() {
        this.menuMap = this.createMap();
    },
    mounted() {
        let menuPath = this.findPath(this.menuMap, this.$route.meta.menuActiveTag);
        if (menuPath) {
            this.$emit('select', menuPath);
        }
    },
    watch: {
        'menuList': function () {
            this.menuMap = this.createMap();
        }
    },
    methods: {
        
        handleSelect(index, indexPath){
            let menuPath = [];
            for (let i = 0; i < indexPath.length; i++) {
                let id = indexPath[i];
                menuPath.push(this.menuMap[id]);
            }
            this.$emit('select', menuPath);
        },
        handleOpen(index){
            let curMenu = this.menuMap[index];
            this.eachTree(this.menuList, (menu, level) => {
                if (level == curMenu.level && menu.id != curMenu.id) {
                    this.$refs.sideMenu.close(String(menu.id));
                }
            });
        },
        handleClose() {

        },
        createMap(){
            let menuMap = {};
            this.eachTree(this.menuList, (menu, level) => {
                menu.level = level;
                if (menu.children) {
                    menuMap[menu.id] = menu;
                } else {
                    menuMap[menu.href] = menu;
                }
            });
            return menuMap;
        },
        eachTree(tree, cb, level = 0) {
            if (!tree) {
                return;
            }
            for(let i = 0; i < tree.length; i++) {
                let menu = tree[i];
                cb.call(this, menu, level);
                this.eachTree(menu.children, cb, level + 1);
            }
        },
        findPath(map, idOrHref) {
            let curMenu = map[idOrHref];
            if (!curMenu) {
                return;
            }
            let pMenu = this.findParent(map, curMenu.id);
            if (!pMenu) {
                return [curMenu];
            }
            return [pMenu, curMenu];
        },
        findParent(map, id) {
            for (let k in map) {
                let menu = map[k];
                if (menu.children) {
                    for (let i = 0; i < menu.children.length; i++) {
                        if (id == menu.children[i].id) {
                            return menu;
                        }
                    }
                }
            }
        }
    }
};
</script>

<style scoped>
.side-menu {
    width: 220px;

    position: fixed;
    top: 64px;
    left: 0;
    bottom: 0;
    z-index: 1020;
    
    overflow: hidden;

    border-right: 1px solid #e3ebed;
    background-color: #f9f9f9;
}
.side-menu /deep/ .el-menu {
    background-color: transparent;
}
.side-menu-inner {
    width: 240px;
    height: 100%;
    overflow-y: scroll;
}
.side-menu-inner .is-opened /deep/ {
    background-color:#fff;
}
.side-menu /deep/ .el-submenu__title:hover,
.el-menu-item:focus, 
.el-menu-item:hover {
    background-color: rgba(0, 0, 0, .1);
}
.side-menu-vertical {
    border-right: none;
}
.side-menu-vertical .el-menu-item.is-active {
    background-color: #0085e8!important;
}
.side-menu-vertical .el-menu-item.is-active [class^=el-icon-] {
    color: #fff!important;
}
.el-menu-item [class^=el-icon-],
.el-submenu [class^=el-icon-] {
    color: #333;
}
</style>